/* ButtonGroup.css */

/* Center the button group container */
.button-group-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 64px;
  }
  
  /* Flex layout for the buttons */
  .button-group {
    display: flex;
    gap: 15px; /* space between buttons */
    flex-wrap: wrap; /* Ensures buttons wrap on smaller screens */
  }
  
  /* Button styles */
  .btn {
    padding: 16px 35px;    
    border-radius: 8px; /* Rounded corners */
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    white-space: nowrap; /* Prevent text from wrapping inside the button */
  }
  
  /* Explore More button style */
  .btn-explore {
    background-color: #A7E8F4; /* Light blue background */
    color: #000; /* Black text */
    border: 2px #A7E8F4;
  }
  
  .btn-explore:hover {
    background-color: #8DD1E1; /* Darker on hover */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Shadow on hover */
  }
  
  /* Try for free button style */
  .btn-try {
    border: 2px solid #279CEF; /* Border color */
    background-color: transparent; /* Transparent background */
    color: #fff; /* White text */
  }
  
  .btn-try:hover {
    background-color: #279CEF; /* Blue background on hover */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Shadow on hover */
    color: #fff;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .btn {
      padding: 12px 24px;
      font-size: 14px; /* Smaller font for smaller screens */
    }
  
    .button-group {
      gap: 10px; /* Reduce gap on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .btn {
      padding: 10px 20px;
      font-size: 12px; /* Even smaller font for very small screens */
    }
    
    /* Ensure buttons are stacked vertically on small screens */
    .button-group {
      flex-direction: column;
    }
  }
  