.benefits-section {
    padding: 50px;
  }
  
  .benefits-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    max-width: 1296px;
    margin: 0 auto; /* Center the grid */
  }
  
  .benefit-card {
    align-items: flex-start;
    background-color: #1a2f38;
    border: 1px solid #2b4c55;
    border-radius: 20px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    text-align: left;
  }
  
  .benefit-id {
    font-size: 48px;
    margin: 0;
  }
  
  .benefit-title {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .benefit-description {
    font-size: 16px;
    margin: 0;
    line-height: 1.5;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .benefits-grid {
      grid-template-columns: 1fr; /* Stack on smaller screens */
    }
  }
  