body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000; /* Background color for the entire page */
  font-family: 'Inter', sans-serif;
}

.container {
  text-align: center;
  padding: 10px;
  margin-top: 80px;
  z-index: 1;
}

.logo {
  width: 150px;
  display: flex;
  margin-left: 75px;
}

.caption {
  font-size: 25px; /* Adjust the font size for your caption */
  margin-top: 20px; /* Adjust the space between the logo and caption */
  /* You can add other styles for your caption here */
}

.coming-soon {
  font-weight: 700;
  font-size: 20px;
}

.hero-section {
  /* top: -120px;
  position: relative; */
  margin-bottom: 50px;
}

.hero-section h1 {
  font-size: 64px;
  /* color: #C3D3E8; */
  color: #fff;
  margin: 0px;
  padding: 0px;
  line-height: 64px;
  font-weight: 600;
}

.hero-section h6 {
  font-size: 14px;
  color: #AEBED3;
  margin: 0px;
  padding: 0px;
  max-width: 500px;
  font-weight: 400;
  padding-top: 20px;
}

.gradient-section {
  display: flex;
  /* justify-content: center;
  align-items: center; */
  /* background: radial-gradient(45.92% 16.01% at 49.97% -3.39%, #01192A 0%, #010101 100%); */
  padding: 20px; /* Add some padding to handle smaller screens */
  flex-direction: column;
  max-width: 80%; /* Set a maximum width for the section */
  margin: 0 auto; /* Center the section */
}

.gradient-section-full-width {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: radial-gradient(45.92% 16.01% at 49.97% -3.39%, #01192A 0%, #010101 100%); */
  flex-direction: column;
  margin: 0 auto; /* Center the section */
}

.stats-container {
  padding: 50px 0;
  text-align: center;
}

.stats-grid {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  gap: 75px;
  grid-template-columns: repeat(3, 1fr);
}

.stats-item {
  position: relative;
  padding: 20px;
}

.stats-item:not(:last-child)::after {
  content: '';
  position: absolute;
  right: -15px; /* Place the line between items */
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #011421;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .gradient-section {
    padding: 20px 10px; /* Adjust padding for smaller screens */
  }

  .stats-grid {
    grid-template-columns: repeat(1, 1fr); /* Stack items vertically on small screens */
    gap: 20px; /* Reduce gap between items */
  }

  .stats-item:not(:last-child)::after {
      right: auto;
      bottom: -15px;
      top: auto;
      height: 2px;
      width: 80%;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
  }

  .services-container {
    margin-top: 20px; /* Add space between the stats and AiDraftingService */
    padding: 20px 0; /* Add padding for better spacing */    
  }
}

.font-primary-h1 {
  color: #C3D3E8;
}

.font-primary {
  color: #C3D3E8;
}

.font-300 {
  font-size: 36px;
  font-weight: 300;
  max-width: 65%;
}

.pt-50{
  padding-top:50px;
}
.pb-50{
  padding-bottom:50px;
}
.text-flex-left {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: start;
  text-align: left;
}

.text-flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.font-400 {
  font-size: 36px;
  font-weight: 500;
  max-width: 65%;
}
.font-500{
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.font-600{
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.mb-0{
  margin-bottom: 0px;
}

.services-container {
  width: 100%;
  display: flex;
  justify-content: center; /* Center the AiDraftingService */
  margin-bottom: 50px;
}

.video-background {
  position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}

.video-background video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.background-overlay{
  background-color: #030114;
  opacity: 0.5;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.parallax-section {
  position: relative;
  background-image: url('../public/images/supreme_court.png');
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 600px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  color: white;
  z-index: 1; /* Make sure content is above the overlay */
}

.parallax-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background-color: #133f57ba;/* Your desired color #030114 with opacity */
  z-index: -1; /* Make sure the overlay is below the content */
}

.footer {
  background-color: #000; /* Black background for the footer */
  color: #fff; /* White text */
  text-align: center;
  padding: 1rem 0;
  font-family: 'Arial', sans-serif;
  font-size: 0.9rem;
  border-top: 1px solid #333; /* Subtle border for separation */
  margin-top: 2rem; /* Space above footer */
}

.footer-links {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  gap: 1rem; /* Space between links */
}

.footer-link {
  color: #bbb; /* Muted white for links */
  text-decoration: none;
  font-size: 0.9rem;
}

.footer-link:hover {
  color: #fff; /* Bright white on hover */
  text-decoration: underline;
}

.footer-text {
  margin: 0;
  color: #bbb; /* Muted white for text */
}

.footer-link.clickable {
  /* text-decoration: underline; */
  cursor: pointer;
}