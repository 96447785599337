/* Section Styling */
.ai-drafting-service {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 40px 20px; /* Adjusted padding for better mobile spacing */
    /*background-color: #081f30;*/
    border-radius: 40px;
    max-width: 80%;
    margin: 0 auto;
    z-index: 1;
  }

  .ai-drafting-service::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    /*background-color: rgb(16 16 17 / 40%);*/ /* Background with 50% opacity */
    border-radius: 40px; /* Same border-radius as the main section */
    z-index: -1; /* Keep it behind the content */
}
  
  /* Content Wrapper to align the sections equally */
  .content-wrapper {
    display: flex;
    align-items: stretch; /* Ensures both sections are the same height */
    width: 100%;
    z-index: 2;
  }
  
  /* Left Section: Video Placeholder */
  .video-placeholder {
    flex: 1; /* Take up equal space */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 414px; /* Set maximum height */
    max-width: 387px; /* Set maximum width */
    width: 100%; /* Allow it to take full width */
  }
  
  .video-placeholder img {
    width: 100%; /* Full width of the container */
    height: 100%; 
    max-height: 100%; /* Constrain height within max-height */
    border-radius: 20px;
    object-fit: cover; /* Ensure image covers the area */
  }
  
  /* Play Button Styling */
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 48px;
    color: white;
    cursor: pointer;
  }
  
  .play-button i {
    pointer-events: none; /* Prevent mouse events on the icon */
  }
  
  /* Right Section: Text Content */
  .text-content {
    flex: 1; /* Take up equal space */
    padding-left: 30px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  
  .text-content h4 {
    color: #6fd3f9;
    margin: 0;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }
  
  .text-content h1 {
    margin-bottom: 5px;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }
  
  .text-content p {
    margin-bottom: 30px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  
  /* Buttons Group */
  .buttons {
    display: flex;
    gap: 15px; /* Space between buttons */
  }
  
  .learn-more-btn {
    padding: 10px 20px;
    background-color: #b2f2e1;
    color: #000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .free-trial-btn {
    padding: 10px 20px;
    background: transparent;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .learn-more-btn:hover,
  .free-trial-btn:hover {
    opacity: 0.8; /* Hover effect for buttons */
  }
  
  /* Responsive Design for Smaller Screens */
  @media (max-width: 1024px) {
    .content-wrapper {
      flex-direction: column; /* Stack sections on smaller screens */
    }
  
    .video-placeholder,
    .text-content {
      flex: none; /* Reset flex */
      width: 100%; /* Take full width */
    }
  
    .text-content h1 {
      font-size: 2rem; /* Smaller font size for better readability */
    }
  
    .text-content p {
      font-size: 1rem; /* Smaller font size for better readability */
    }
  
    .play-button {
      font-size: 36px; /* Smaller play button on smaller screens */
    }
  }
  
  @media (max-width: 768px) {
    .ai-drafting-service {
      padding: 20px 10px; /* Reduced padding for smaller screens */
    }
  
    .text-content h1 {
      font-size: 1.5rem; /* Even smaller font size for heading */
    }
  
    .text-content p {
      font-size: 0.9rem; /* Smaller font size for paragraph */
    }
  
    .play-button {
      font-size: 30px; /* Further reduce the play button size */
    }
  }
  
  @media (max-width: 480px) {
    .text-content {
      padding: 15px; /* Smaller padding on very small screens */
    }
  
    .text-content h1 {
      font-size: 1.25rem; /* Smaller heading size for very small devices */
    }
  
    .text-content p {
      font-size: 0.8rem; /* Smaller paragraph size for very small devices */
    }
  
    .learn-more-btn,
    .free-trial-btn {
      padding: 8px 16px; /* Smaller button padding */
    }
  }
  