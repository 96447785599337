.navbar {
  position: absolute;
    top: 0;
    width: 100%;
    /* background-color: #000000; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* opacity: .5; */
}

/* .logo {
  
} */

.menu {
  margin-right: 75px; /* Ensure some space between the menu and the edge */
}

.menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 10px; /* Add space between menu items */
}

.menu ul li {
  display: inline-block;
}

.menu ul li a {
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s ease;
}

.menu-selected{
  border-radius: 8px;
  background-color: #279CEF;
  color: #FFFFFF;
}

/* Hamburger Menu Styles */
.hamburger {
  display: none; /* Hide by default */
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 4px 0;
  transition: 0.3s;
}

/* Open state animation */
.bar.open {
  transform: rotate(45deg);
  &:nth-child(2) {
    opacity: 0; /* Hide the middle bar */
  }
}

.bar.open:nth-child(3) {
  transform: rotate(-45deg);
}

/* Media Queries for responsiveness */
@media (max-width: 1200px) {
  .menu {
    display: none; /* Hide the menu on small screens */
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #000;
  }

  .menu.open {
    display: flex; /* Show menu when open */
  }

  .hamburger {
    display: flex; /* Show hamburger menu on small screens */
    margin-right: 75px;
  }

  .menu ul {
    flex-direction: column; /* Stack items vertically */
  }

  .menu ul li {
    width: 100%; /* Full width for items */
    text-align: center; /* Center align text */
  }
}
