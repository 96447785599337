.faq-section {
    padding: 40px;   
    color: #FFF; /* Text color */
  }
  
  .faq-title {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .faq-list {
    border-top: 1px solid #2A2A2A;
  }
  
  .faq-item {
    border-bottom: 1px solid #2A2A2A;
    padding: 25px;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: #F6F9FD;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
  
  .faq-answer {
    margin-top: 25px;
    color: #F6F9FD;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 200%;
  }
  
  .faq-question h3 {
    margin: 0;
  }
  
  .faq-question span {
    font-size: 24px;
    color: #B0B0B0; /* Slightly lighter than white for the plus/minus */
  }
  
  .faq-item:hover {
    background-color: #1e1e1e38; /* Slight hover effect */
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .faq-title {
      font-size: 28px;
    }
  
    .faq-question {
      font-size: 16px;
    }
  
    .faq-answer {
      font-size: 14px;
    }
  }
  