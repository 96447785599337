.privacy-policy{
  color: #fff;
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1000px;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}

.privacy-policy-title {
  font-size: 24px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
}

.privacy-policy > div {
  margin-bottom: 16px;
}

/* Subsection titles */
.privacy-policy > div:nth-child(odd):not(.privacy-policy-title) {
  font-weight: bold;
  margin-top: 20px;
  font-size: 18px;
}

/* Nested content styling */
.privacy-policy ul {
  list-style-type: none; /* Remove the default bullet styling */
  padding-left: 20px; /* Indent the list for better readability */
  margin: 10px 0;
}

.privacy-policy ul li {
  margin-bottom: 8px;
}
.privacy-policy li::before {
  content: "-"; /* Add a dash before each list item */
  margin-right: 10px; /* Add spacing between the dash and the text */
  color: #fff; /* Optional: Set the dash color */
  font-weight: bold; /* Optional: Make the dash bold */
}

.privacy-policy div div,
.privacy-policy ul li {
  font-size: 15px;
}

/* Bullet points for nested lists */
.privacy-policy ul li ul {
  list-style-type: circle;
  margin-top: 8px;
}

.privacy-policy ul li ul li {
  margin-bottom: 6px;
}

/* Responsive styling */
@media (max-width: 768px) {
  .privacy-policy {
    font-size: 14px;
    padding: 15px;
  }

  .privacy-policy-title {
    font-size: 20px;
  }

  .privacy-policy > div:nth-child(odd):not(.privacy-policy-title) {
    font-size: 16px;
  }
}