.countup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    font-size: 2rem;
    color: white;
  }
  
  .countup-number {
    display: flex;
    align-items: baseline;
    font-size: 3rem;
  }
  
  .countup-number span {
    font-size: 72px;
    font-weight: 500;
    margin-left: 2px;
  }

  .font-blue{
    color:#279CEF;
  }
  
  .countup-label {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    line-height: 22.4px;
    margin-top: 18px;
  }
  
  @media (max-width: 768px) {
    .countup-number {
      font-size: 2.5rem;
    }
  
    .countup-label {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .countup-number {
      font-size: 2rem;
    }
  
    .countup-label {
      font-size: 0.9rem;
    }
  }
  